import { useSystemStore } from "@/store/system.store";
import { AxiosError } from "axios";
import i18n from "@/i18n";

const {
  global: { t },
} = i18n;

export function errorHandler(
  error: Error | unknown | AxiosError,
  isShowNotification: boolean
) {
  const systemStore = useSystemStore();
  let errorMessage: string | undefined;

  if (error instanceof AxiosError) {
    errorMessage = error.response?.data?.message || error.response?.data?.error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else {
    errorMessage = String(error);
  }

  if (!errorMessage) return;

  if (error instanceof AxiosError && error?.response?.status === 401) {
    return;
  }

  if (isShowNotification) {
    systemStore.showNotification({
      type: "error",
      title: t("system.error"),
      text: errorMessage,
    });
  } else {
    console.error(errorMessage);
  }
}
