import i18n from "@/i18n";
import { nextTick } from "vue";
// import { getSystemCountry } from "@/package/global-helpers/system-country";
import { setDefaultOptions } from "date-fns";
import { ru, ka, enGB } from "date-fns/locale";

const Translate = {
  get supportedSystemLocales() {
    // if (getSystemCountry() === "kz") {
    //   return ["ru", "en", "kz"];
    // }

    return ["ru", "en"];
  },

  isLocaleSupported(locale: string) {
    return Translate.supportedSystemLocales.includes(locale);
  },

  getUserSystemLocale() {
    const locale =
      window.navigator.language || import.meta.env.VITE_DEFAULT_LOCALE;

    return locale.split("-")[0];
  },

  getSavedLocale() {
    const savedLocale = localStorage.userLocale;

    if (Translate.isLocaleSupported(savedLocale)) {
      return savedLocale;
    }

    return null;
  },

  initUserLocale() {
    const userSavedLocale = Translate.getSavedLocale();

    if (userSavedLocale) {
      Translate.switchLanguage(userSavedLocale);
      return;
    }

    const getUserSystemLocale = Translate.getUserSystemLocale();

    if (Translate.isLocaleSupported(getUserSystemLocale)) {
      Translate.switchLanguage(getUserSystemLocale);
      return;
    }

    Translate.switchLanguage(import.meta.env.VITE_DEFAULT_LOCALE);
  },

  async switchLanguage(newLocale: string) {
    await Translate.loadLocaleMessages(newLocale);
    i18n.global.locale.value = newLocale;
    (document.querySelector("html") as HTMLHtmlElement).setAttribute(
      "lang",
      newLocale
    );
    setDefaultOptions({
      locale: newLocale === "ru" ? ru : newLocale === "kz" ? ka : enGB,
    });
    localStorage.setItem("userLocale", newLocale);
  },

  async loadLocaleMessages(locale: string) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`./locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages);
    }

    return nextTick();
  },
};

export default Translate;
