<script setup lang="ts">
import { UiButton, UiModal } from "@packages/component-library";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
</script>

<template>
  <UiModal hide-header size="s" @close="router.push({ name: route.name })">
    <template #default>
      <div class="success-order">
        <img src="@/assets/images/error.svg" alt="" />
        <h5>{{ t("publicOrdering.failedModal.title") }}</h5>
        <p>{{ t("publicOrdering.failedModal.description") }}</p>
      </div>
    </template>

    <template #actions>
      <div class="success-order__actions">
        <UiButton
          :label="t('publicOrdering.failedModal.actionButton')"
          color="secondary"
          size="m"
          :to="{ name: 'HomeView' }"
        />
      </div>
    </template>
  </UiModal>
</template>

<style scoped lang="scss">
.success-order {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > h5 {
    @include h4();
    text-align: center;
    color: var(--color-semantic-content-normal-primary);
    margin: calc($base-space * 4) 0 0;
  }

  > p {
    margin: calc($base-space * 2) 0 0;
    @include body-m();
    color: var(--color-semantic-content-normal-primary);
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: calc($base-space * 2);

    > a {
      width: 100%;
    }
  }
}
</style>
