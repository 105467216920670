import { createRouter, createWebHistory } from "vue-router";
import { computed } from "vue";
import i18n from "@/i18n";
import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies();

const {
  global: { t },
} = i18n;

const router = createRouter({
  scrollBehavior(to, from) {
    if (from.name !== to.name) {
      return { top: 0 };
    }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: "",
          name: "HomeView",
          component: () => import("@/views/HomePage.vue"),
        },
      ],
    },

    {
      path: "/r",
      children: [
        {
          path: "",
          component: () => import("@/views/ReferralRedirect.vue"),
          beforeEnter: (to, from, next) => {
            next({ name: "HomeView" });
          },
        },
        {
          path: ":code",
          component: () => import("@/views/ReferralRedirect.vue"),
          beforeEnter: (to, from, next) => {
            if (cookies.get("ksUserToken")) {
              next({ name: "HomeView" });
              return;
            }

            next();
          },
        },
      ],
    },

    {
      path: "/registration",
      children: [
        {
          path: "",
          component: () => import("@/views/ReferralRedirect.vue"),
          beforeEnter: (to, from, next) => {
            if (cookies.get("ksUserToken")) {
              next({ name: "HomeView" });
              return;
            }

            next();
          },
        },
      ],
    },

    {
      path: "/yoomoney",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: "",
          component: () => import("@/views/YooMoneyAuth.vue"),
          beforeEnter: (to, from, next) => {
            next({ name: "HomeView" });
          },
        },

        {
          path: "auth",
          component: () => import("@/views/YooMoneyAuth.vue"),
        },
      ],
    },

    {
      path: "/spend",
      component: () => import("@/layouts/MainLayout.vue"),
      meta: {
        breadcrumb: computed(() => t("nav.spend")),
      },
      children: [
        {
          path: "",
          name: "SpendView",
          component: () => import("@/views/SpendPage.vue"),
        },
        {
          path: "fund",
          children: [
            {
              path: "",
              name: "FundView",
              component: () => import("@/views/Fund/FundPage.vue"),
            },

            {
              path: "news",
              children: [
                {
                  path: "",
                  name: "FundNewsListView",
                  component: () => import("@/views/Fund/FundNewsListPage.vue"),
                },
                {
                  path: ":id",
                  name: "FundNewsView",
                  component: () => import("@/views/Fund/FundNewsPage.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "places",
          component: () => import("@/layouts/PlacesLayout.vue"),
          meta: {
            breadcrumb: computed(() => t("catalogs.places")),
          },
          children: [
            {
              path: "",
              name: "SpendPlacesView",
              component: () => import("@/views/Places/PlacesView.vue"),
            },
            {
              path: ":id",
              name: "SpendPlacesCategoryView",
              component: () => import("@/views/Places/PlacesCategoryView.vue"),
            },
          ],
        },
        {
          path: "coupons",
          component: () => import("@/layouts/CouponsLayout.vue"),
          meta: {
            breadcrumb: computed(() => t("catalogs.coupons")),
          },
          children: [
            {
              path: "",
              name: "CouponsView",
              component: () => import("@/views/Coupons/CouponsView.vue"),
            },
            {
              path: ":id",
              name: "CouponsCategoryView",
              component: () =>
                import("@/views/Coupons/CouponsCategoryView.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/receive",
      component: () => import("@/layouts/MainLayout.vue"),
      meta: {
        breadcrumb: computed(() => t("nav.receive")),
      },
      children: [
        {
          path: "",
          name: "ReceiveView",
          component: () => import("@/views/ReceivePage.vue"),
        },
        {
          path: "places",
          component: () => import("@/layouts/PlacesLayout.vue"),
          meta: {
            breadcrumb: computed(() => t("catalogs.places")),
          },
          children: [
            {
              path: "",
              name: "ReceivePlacesView",
              component: () => import("@/views/Places/PlacesView.vue"),
            },
            {
              path: ":id",
              name: "ReceivePlacesCategoryView",
              component: () => import("@/views/Places/PlacesCategoryView.vue"),
            },
          ],
        },
        {
          path: "offers",
          component: () => import("@/layouts/OffersLayout.vue"),
          meta: {
            breadcrumb: computed(() => t("catalogs.offers")),
          },
          children: [
            {
              path: "",
              name: "OffersView",
              component: () => import("@/views/Offers/OffersPage.vue"),
            },
            {
              path: ":id",
              name: "OffersCategoryView",
              component: () => import("@/views/Offers/OffersCategoryPage.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/cart",
      component: () => import("@/layouts/MainLayout.vue"),
      beforeEnter: (to, from, next) => {
        if (!cookies.get("ksUserToken")) {
          next({ name: "HomeView" });
          return;
        }

        next();
      },
      children: [
        {
          path: "",
          name: "CartView",
          component: () => import("@/views/Cart/CartView.vue"),
        },
        {
          path: ":id",
          name: "CartOrdering",
          component: () => import("@/views/Cart/CartOrderingView.vue"),
        },
      ],
    },

    {
      path: "/profile",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: "",
          name: "ProfileView",
          component: () => import("@/views/Profile/ProfilePage.vue"),
        },
        {
          path: "languages",
          name: "ProfileLanguageView",
          component: () => import("@/views/Profile/ProfileLanguagePage.vue"),
        },
        {
          path: "documents",
          name: "ProfileDocumentsView",
          component: () => import("@/views/Profile/ProfileDocumentsPage.vue"),
        },
      ],
    },

    {
      path: "/profile",
      component: () => import("@/layouts/ProfileLayout.vue"),
      beforeEnter: (to, from, next) => {
        if (!cookies.get("ksUserToken")) {
          next({ name: "HomeView" });
          return;
        }

        next();
      },
      children: [
        {
          path: "invite",
          name: "ProfileInvite",
          component: () => import("@/views/Profile/ProfileInvitePage.vue"),
        },

        {
          path: "tariffs",
          name: "ProfileTariffs",
          component: () => import("@/views/Profile/ProfileTariffsPage.vue"),
        },

        {
          path: "favorites",
          name: "ProfileFavorites",
          component: () => import("@/views/Profile/ProfileFavoritesPage.vue"),
        },

        {
          path: "orders",
          children: [
            {
              path: "",
              name: "ProfileOrders",
              component: () => import("@/views/Profile/ProfileOrdersPage.vue"),
            },
            {
              path: ":id",
              name: "ProfileOrder",
              component: () => import("@/views/Profile/ProfileOrderPage.vue"),
            },
          ],
        },

        {
          path: "coupons",
          name: "ProfileCoupons",
          component: () => import("@/views/Profile/ProfileCouponsPage.vue"),
        },

        {
          path: "gift-cards",
          name: "ProfileGiftCards",
          component: () => import("@/views/Profile/ProfileGiftCardsPage.vue"),
        },

        {
          path: "active",
          name: "ProfileActivate",
          component: () => import("@/views/Profile/ProfileActivatePage.vue"),
        },
      ],
    },

    {
      path: "/profile",
      component: () => import("@/layouts/ScoresLayout.vue"),
      beforeEnter: (to, from, next) => {
        if (!cookies.get("ksUserToken")) {
          next({ name: "HomeView" });
          return;
        }

        next();
      },
      children: [
        {
          path: "scores",
          children: [
            {
              path: "",
              name: "ProfileScores",
              component: () => import("@/views/Scores/ScorePage.vue"),
            },
            {
              path: ":id",
              children: [
                {
                  path: "",
                  name: "ProfileScore",
                  component: () => import("@/views/Scores/ScorePage.vue"),
                },
                {
                  path: "transfer-scores",
                  name: "ProfileTransferScores",
                  component: () =>
                    import("@/views/Scores/ScoreTransferScores.vue"),
                },
                {
                  path: "transfer-client",
                  name: "ProfileTransferClient",
                  component: () =>
                    import("@/views/Scores/ScoreTransferClient.vue"),
                },
              ],
            },
            {
              path: "create",
              name: "ProfileScoresCreate",
              component: () => import("@/views/Scores/ScoresCreatePage.vue"),
            },

            {
              path: "statistics",
              name: "ProfileScoresStatistic",
              component: () => import("@/views/Scores/ScoresStatisticPage.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/profile/orders",
      component: () => import("@/layouts/MainLayout.vue"),
      beforeEnter: (to, from, next) => {
        if (!cookies.get("ksUserToken")) {
          next({ name: "HomeView" });
          return;
        }

        next();
      },
      children: [
        {
          path: ":id",
          name: "ProfileOrder",
          component: () => import("@/views/Profile/ProfileOrderPage.vue"),
        },
      ],
    },

    {
      path: "/order",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: "",
          component: () => import("@/layouts/MainLayout.vue"),
          beforeEnter: (to, from, next) => {
            next({ name: "HomeView" });
          },
        },
        {
          path: ":id",
          name: "OrderPayment",
          component: () => import("@/views/PublicOrdering.vue"),
        },
      ],
    },

    {
      path: "/documents",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: ":id",
          name: "DocumentView",
          component: () => import("@/views/DocumentPage.vue"),
        },
      ],
    },

    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: "",
          name: "NotFound",
          component: () => import("@/views/NotFoundPage.vue"),
        },
      ],
    },

    {
      path: "/app-share",
      component: () => import("@/views/AppSharePage.vue"),
    },

    // OLD PATHS

    {
      path: "/cashback/category/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/places/city/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/cashback/product/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/places/place/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/place/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/coupons/category/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/coupons/product/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/menu/:id",
      component: () => import("@/views/OldRedirects.vue"),
    },

    {
      path: "/payments/sbp",
      component: () => import("@/views/PaymentPage.vue"),
    },

    {
      path: "/invoice/:id",
      component: () => import("@/views/InvoicePage.vue"),
    },
  ],
});

router.beforeEach((to) => {
  // const mainStore = useMainStore(); //pinia указывается тут

  // if (!mainStore.isAuthorized && to.name !== "Authorization") {
  //   return { name: "Authorization" };
  // }

  // @ts-ignore
  document.title = to.meta.title ?? window.document.title;
});

export default router;
